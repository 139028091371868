@use '~/src/overtime-lib/src/www/lib/mixins';

.PageHeader {
	background-color: var(--color-brand-primary);
	padding: var(--size-gap);
	color: var(--color-white);
	@include mixins.desktop {
		padding: var(--size-gap) 0;
	}
	.HeaderContainer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		svg {
			padding-left: 4px;
		}
	}
	.Link {
		font-size: 14px;
	}
	a {
		color: var(--color-white);
		&:hover {
			text-decoration: none;
		}
	}
}
