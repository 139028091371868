@use '~/src/overtime-lib/src/www/lib/mixins';

.Footer {
	display: flex;
	flex-direction: column;
	background-color: var(--color-black);
	color: var(--color-white);

	a {
		color: var(--color-white);
		text-decoration: none;
	}

	nav {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		gap: 56px;
		padding: 40px 16px;

		@include mixins.desktop {
			justify-content: space-between;
			gap: 16px;
			padding: 96px 16px;
		}

		.LogoContainer {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;

			.Logo {
				display: flex;
				flex-direction: column;
				gap: 16px;
			}
		}

		.LinkContainer {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-evenly;
			gap: 32px 58px;
			padding: 24px;
			width: 100%;

			@include mixins.desktop {
				gap: 88px;
				padding: 0;
				width: unset;
			}

			.Column {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 16px;

				@include mixins.desktop {
					align-items: flex-start;
				}

				.Heading {
					color: #979797;
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 133%; /* 23.94px */
					font-family: 'VTF League';
				}

				.Links {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 8px;
					color: #fff;
					font-style: normal;
					font-weight: 400;
					font-size: 14.4px;
					line-height: 150%; /* 21.6px */

					@include mixins.desktop {
						align-items: flex-start;
					}
				}

				&.Follow {
					gap: 24px;

					.Links {
						gap: 12px;
					}
				}

				.Social {
					display: flex;
					flex-direction: row;
					gap: 8px;
				}
			}
		}
	}

	.Teams {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 16px;

		.Logo {
			padding: 0 8px;
			width: 100%;
			text-align: center;

			img {
				width: 100%;
				max-width: 40px;
			}
		}
	}

	.LegalFooter {
		display: flex;
		flex-direction: column;
		background-color: #000;
		padding: 24px 0;

		.LegalContainer {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 8px;
			padding: 0 16px;
			color: #979797;
			font-style: normal;
			font-weight: 700;
			font-size: 12px;
			line-height: 150%; /* 18px */
			text-align: center;

			@include mixins.desktop {
				flex-direction: row;
				justify-content: space-between;
				align-items: flex-start;
				padding: 0;
				text-align: left;
			}

			a {
				color: #979797;
				text-decoration: none;
			}

			.Copyright {
				display: flex;
				flex-direction: column;
				gap: 8px;
				max-width: 438px;
			}

			.Links {
				display: flex;
				flex-direction: row;
				gap: 8px;
			}

			.Pokemon {
				margin-top: 8px;
			}
		}
	}
}
