@use '~/src/overtime-lib/src/www/lib/mixins';

.Table {
	border-radius: unset;
	padding: 0;
	width: 100%;
	max-width: var(--app-width);
	overflow-x: scroll;
	overflow-x: auto;
	overscroll-behavior-x: contain;

	@include mixins.desktop {
		border-radius: var(--size-gap);
		max-width: 100%;
	}

	table {
		border-width: 0;
		border-spacing: 0;
		width: 100%;
		table-layout: fixed;
	}

	thead tr th {
		border-bottom: 1px solid var(--color-gray);
		background-color: var(--color-white);
		padding: var(--size-gap);
		color: var(--color-black);
		font-weight: 400;
		font-size: var(--font-small);
		text-align: center;
		text-transform: uppercase;
		white-space: nowrap;

		&:first-child {
			position: sticky;
			left: 0;
			z-index: 1;
			border-right: 1px solid var(--color-gray);
			padding: var(--size-gap-half);
		}

		&.current {
			background-color: var(--color-brand-secondary);
			color: var(--color-white);
		}

		&:hover {
			cursor: pointer;
		}

		&.Title {
			padding-left: var(--size-gap);
			text-align: left;
		}
	}

	tbody {
		a {
			color: var(--color-black);
			text-decoration: none;
		}

		th {
			position: sticky;
			left: 0;
			z-index: 1;
			border-right: 1px solid var(--color-gray);
			border-bottom: 1px solid var(--color-lightgray);
			background-color: var(--color-white);
			padding: var(--size-gap);
			font-size: var(--font-small);
			font-family: var(--font-main);
			text-align: left;
		}

		td {
			border-bottom: 1px solid var(--color-lightgray);
			background-color: var(--color-white);
			font-size: var(--font-small);
			text-align: center;

			&.current {
				background-color: #f4f3f3;
				font-weight: 600;
			}
		}
	}
}
