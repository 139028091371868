@use '~/src/lib/_root';
@use '~/src/overtime-lib/src/www/lib/mixins';

.YouTubeVideos {
	padding-bottom: var(--size-gap);
	padding: 16px var(--size-gap);

	@include mixins.desktop {
		padding: 0;
	}

	.Videos {
		display: grid;
		grid-template-rows: 1fr auto auto;
		align-items: flex-end;
		gap: 16px;
		border-radius: 16px;

		background: linear-gradient(90deg, rgba(30, 30, 30, 0.8) 0%, rgba(0, 0, 0, 0) 57.96%),
			linear-gradient(180deg, rgba(0, 0, 0, 0) 45.92%, rgba(30, 30, 30, 0.8) 100%),
			linear-gradient(0deg, rgba(30, 30, 30, 0.8) 0%, rgba(30, 30, 30, 0.8) 100%),
			linear-gradient(0deg, #1e1e1e 0%, #1e1e1e 100%);
		background-blend-mode: normal, normal, normal, hue;
		max-width: 990px;
		height: 447px;

		video {
			position: absolute;
			z-index: 0;
			border-radius: 16px;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.VideoOverlay {
			position: absolute;
			border-radius: 16px;
			background: linear-gradient(180deg, rgba(50, 50, 50, 0.4) 0%, rgba(0, 0, 0, 1) 90%);
			width: 100%;
			height: 100%;
		}

		.VideosTitleContainer {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding: 0 var(--size-gap);
			color: var(--color-white);

			h2 {
				font-size: 27px;
			}

			.MoreLink {
				a {
					color: var(--color-white);
					font-size: 14px;

					svg {
						padding-left: var(--size-gap-quarter);
					}
				}
			}
		}

		.VideosListContainer {
			display: grid;
		}

		.VideosList {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			gap: 10px;
			padding: 0 var(--size-gap);
			overflow-x: auto;
			overscroll-behavior-x: contain;
			scroll-behavior: smooth;
			scroll-snap-type: x mandatory;
			> div {
				scroll-snap-align: center;
			}

			@include mixins.desktop {
				grid-template-columns: repeat(4, 1fr);
			}

			.SmallVideo {
				.ThumbnailContainer {
					display: grid;
					align-items: center;
					justify-items: center;
					border: 1px solid var(--color-white);
					border-radius: 8px;
					background-color: var(--color-black);
					padding: 8px;
					min-width: 200px;

					.Thumbnail {
						display: block;
						grid-area: 1 / 1;
						border-radius: 8px;
						width: 100%;
					}
					.Play {
						grid-area: 1 / 1;
						width: 40px;
					}
				}
			}
		}
	}
}

.YouTubeShorts {
	margin: 0;
	background-color: var(--color-black);
	padding: 16px 16px;

	&.light {
		background-color: var(--color-lightgray);

		h1 {
			color: var(--color-black);
		}
	}

	@include mixins.desktop {
		padding: 32px 0;
	}

	.ContentContainer > div {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

	h1 {
		color: var(--color-white);
	}

	.Heading {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		h2 {
			color: var(--color-white);
			font: var(--font-headline-m);
		}
		.More {
			display: block;
			color: var(--color-brand-primary);
			font: var(--font-body-s-r);
			font-weight: 600;

			svg {
				margin-left: 4px;
				color: var(--color-brand-primary);
			}

			&.SoldOut {
				color: var(--color-gray);
				text-decoration: line-through;
			}
		}
	}

	.VideosListContainer {
		display: grid;
	}

	.VideosList {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		gap: 8px;
		margin: 0;
		padding-right: var(--size-gap);
		width: calc(100% + var(--size-gap));
		overflow-x: auto;
		overscroll-behavior-x: contain;
		scroll-behavior: smooth;
		scroll-snap-type: x mandatory;
		> div {
			scroll-snap-align: start;
		}

		.Video {
			display: flex;
			flex-shrink: 1;
			flex-direction: column;
			gap: var(--size-gap-quarter);
			border-radius: 18px;
			background-color: #000000;
			padding: 8px;
			width: 189px;
			height: 100%;
			overflow: hidden;
			color: var(--color-white);

			&:hover {
				.ThumbnailContainer {
					&:after {
						background-color: rgba(0, 0, 0, 0);
					}
				}
			}

			&.Short {
				min-width: 190px;
			}

			.ThumbnailContainer {
				&:after {
					position: absolute;
					top: 0;
					left: 0;
					z-index: 1;
					transition: background-color 0.2s ease;
					background-color: rgba(0, 0, 0, 0.1);
					width: 175px;
					height: 310px;
					content: '';
				}
			}

			.Thumbnail {
				display: block;
				border-radius: 10px;
				aspect-ratio: 16/9;
				width: 100%;
				object-fit: cover;

				&.Short {
					width: 175px;
					height: 310px;
				}
			}

			&:hover {
				text-decoration: none;
			}

			.TextContainer {
				padding: 8px 16px;
				color: var(--color-brand-primary);

				.Title {
					color: var(--color-white);
					font: var(--font-title-s);
					@include mixins.max-lines(3);
				}

				.Description {
					@include mixins.max-lines(2);
					color: var(--color-white);
					font-size: var(--font-small);
					line-height: 1.5;
				}
			}
		}
	}
}
