@use '~/src/overtime-lib/src/www/lib/mixins';

.BoxScore {
	.Table {
		thead tr th {
			width: 60px;
		}
		tbody {
			.Average {
				th,
				td {
					border-top: 1px solid var(--color-gray);
				}
			}
		}

		.Title {
			transition: width 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
			padding: 8px;
			width: 210px;
			height: 40px;

			@include mixins.desktop {
				width: 220px;
			}

			&--Collapsed {
				width: 90px;

				&--Long {
					width: 120px;
				}
			}

			.TitleContentContainer {
				display: flex;
				justify-content: space-between;
			}

			.CollapseExpandContainer {
				display: flex;

				.CollapseExpandIcon {
					transform: scaleX(1);
					transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
					height: 18px;

					&--Collapsed {
						transform: scaleX(-1);
					}
				}
			}
		}

		.Statistic {
			min-width: 40px;
		}
		.LeftHeader {
			padding: 8px;

			.Profile {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: var(--size-gap-half);
				color: var(--color-black);
				text-decoration: none;

				.RankImageContainer {
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 4px 0;

					.StatRank {
						width: 28px;
						color: #000;
						font-size: 16px;
						text-align: center;

						p {
							margin: 0;
						}
					}

					.ImageNumberContainer {
						display: flex;
						position: relative;
						padding-right: 12px;
						padding-left: 4px;

						.JerseyNumber {
							display: flex;
							position: absolute;
							right: 0px;
							bottom: 0px;
							justify-content: center;
							align-items: center;
							z-index: 1;
							border-radius: 50%;
							background-color: var(--color-black);
							width: 20px;
							height: 20px;
							font-size: 12px;
							font-family: var(--font-highlight);
						}

						.TeamLogo,
						.PlayerImage {
							border-radius: 50%;
							width: 48px;
							height: 48px;
						}
					}
				}

				.NameContainer {
					display: flex;
					opacity: 1;
					transition: opacity 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
					overflow: hidden;
					color: #000;
					line-height: 16px;
					text-overflow: ellipsis;

					&--FadeOut {
						opacity: 0;
					}
					&--FadeIn {
						opacity: 1;
					}

					.Name {
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
				.TeamLogo {
					display: flex;
					align-items: center;
					background-size: contain;
					background-repeat: no-repeat;
					width: 32px;
					height: 32px;
					color: var(--color-white);
					font-weight: 600;
					font-size: var(--font-h3);
					font-family: var(--font-highlight);
				}
			}
		}

		.Average {
			th,
			td {
				border-top: 1px solid var(--color-gray);
			}
		}
	}
}
