.cookie-consent {
  z-index: 100;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  color: #000;
  background-color: #ffffffd9;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100px;
  padding: 16px;
  transition: transform .3s ease-in .1s;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
}

.cookie-consent--dark {
  color: #fff;
  background-color: #000000d9;
}

.cookie-consent--dark .cookie-consent__container button {
  color: #fff;
  border: 1px solid #fff;
}

.cookie-consent--visible {
  transition-delay: 1s;
  transform: translateY(0);
}

.cookie-consent__container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  font-size: 12px;
  font-weight: 700;
  display: flex;
}

.cookie-consent__container svg {
  flex-shrink: 0;
  width: 25px;
  height: 30px;
}

.cookie-consent__container button {
  cursor: pointer;
  background-color: unset;
  color: #000;
  border: 1px solid #000;
  border-radius: 4px;
  width: 100%;
  max-width: 80px;
  padding: 8px;
  font-size: 12px;
  font-weight: 700;
}

:root {
  --color-brand-primary: #fc4506;
  --color-brand-secondary: #46928f;
  --color-brand-link: #46928f;
  --color-brand-primary-dark: #d61b00;
  --color-brand-primary-modal: #141923fa;
  --color-brand-primary-overlay: #141923b3;
  --color-offblack: #333;
  --color-black: #1e1e1e;
  --color-white: #fff;
  --color-gray: #979797;
  --color-darkgray: #1c1e21;
  --color-lightgray: #efefef;
  --color-offwhite: #fafafa;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --color-amazon-blue: #1a98ff;
  --color-youtube-red: red;
  --font-brand: alternate-gothic-no-3-d, sans-serif;
  --font-main: "roboto flex", helvetica neue, helvetica, arial, sans-serif;
  --font-highlight: vtf league, helvetica neue, helvetica, arial, sans-serif;
  --font-h1: 1.8rem;
  --font-h2: 1.4rem;
  --font-h3: 1.25rem;
  --font-small: .9rem;
  --font-tiny: .7rem;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-large-mobile-width: 640px;
  --size-tablet-width: 768px;
  --size-desktop-width: 990px;
  --size-content-width: 990px;
}

@font-face {
  font-style: normal;
  font-weight: normal;
  src: url("vtf.ec756ca2.woff2") format("woff2"), url("vtf.5fa2af60.woff") format("woff");
  font-family: VTF League;
  font-display: swap;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/anton/v25/1Ptgg87LROyAm3Kz-C8CSKlv.woff2") format("woff2");
  font-family: Anton;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/chango/v27/2V0cKI0OB5U7WaJCyHe5blYKAg.woff2") format("woff2");
  font-family: Chango;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-wmF8tqHbFvug.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-UmF8tqHbFvug.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-ImF8tqHbFvug.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-4mF8tqHbFvug.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-8mF8tqHbFvug.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-EmF8tqHbE.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-wmF8tqHbFvug.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-UmF8tqHbFvug.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-ImF8tqHbFvug.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-4mF8tqHbFvug.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-8mF8tqHbFvug.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-EmF8tqHbE.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
  --header-background-color: black;
  --header-foreground-color: var(--color-white);
  --app-height: 100vh;
  --app-width: 100vw;
}

* {
  box-sizing: border-box;
  position: relative;
}

html {
  flex-direction: column;
  max-width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.4;
  display: flex;
}

body {
  background: var(--color-white);
  font-family: var(--font-main);
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

p, li {
  line-height: 1.7;
}

input {
  font-family: var(--font-main);
}

body, #root {
  flex: 1;
  grid-template-rows: 1fr;
  grid-template-columns: [full-start] 1fr[full-end];
  display: grid;
}

.content {
  grid-column: full;
  grid-template-rows: 1fr;
  grid-template-columns: [full-start content-start] 1fr[content-end full-end];
  display: grid;
}

@media (width >= 990px) {
  .content {
    grid-template-columns: [full-start] 1fr [content-start] var(--size-content-width) [content-end] 1fr [full-end];
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  font-family: var(--font-highlight);
  margin: 0;
}

body.font-loaded h1, body.font-loaded h2, body.font-loaded h3, body.font-loaded h4, body.font-loaded h5, body.font-loaded h6 {
  font-family: var(--font-highlight);
  letter-spacing: 0;
}

h1 {
  font-size: var(--font-h1);
}

h2 {
  font-size: var(--font-h2);
}

h3 {
  font-size: var(--font-h3);
}

time {
  display: contents;
}

img {
  display: inline-block;
}

button {
  cursor: pointer;
  box-shadow: none;
  font-size: inherit;
  border: 0;
  outline: none;
}

[role="button"]:hover {
  cursor: pointer;
}

b {
  font-weight: 600;
}

:root {
  --gap: min(16px, 5vw);
  --row-gap: .55rem;
}

@media (width >= 1024px) {
  :root {
    --gap: 16px;
  }
}

@media (width <= 990px) {
  .desktop-only {
    display: none !important;
  }
}

@media (width >= 990px) {
  .mobile-only {
    display: none !important;
  }
}

a {
  color: var(--color-brand-primary);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a.inherit {
  color: inherit;
  text-decoration: none;
}

a.inherit:hover {
  text-decoration: underline;
}

.padded {
  padding: var(--size-gap);
}

.LoadingBoundarySuspense {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.LoadingBoundaryError {
  justify-content: center;
  align-items: center;
  gap: var(--size-gap-half);
  font-family: var(--font-highlight);
  flex-direction: row;
  display: flex;
}

.ContentContainer > div {
  grid-column: content;
}

.MenuIcon {
  padding: 4px;
}

@media (width >= 1024px) {
  .MenuIcon {
    display: none;
  }
}

.MenuIcon[aria-expanded="true"] {
  padding-left: 5px;
  padding-right: 5px;
}

.MobileMenu {
  z-index: -1;
  content-visibility: visible;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width >= 1024px) {
  .MobileMenu {
    display: none;
  }
}

.MobileMenu .Menu {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding-bottom: var(--size-gap);
  width: var(--app-width);
  overscroll-behavior: contain;
  color: var(--theme-foreground-color);
  background-color: #000000d9;
  height: calc(100dvh - 100%);
  transition: transform .3s;
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  overflow-y: auto;
}

.MobileMenu .Menu[aria-hidden="true"] {
  transform: translateY(-100%);
}

.MobileMenu .Menu nav {
  gap: var(--size-gap-half);
  text-transform: none;
  flex-direction: column;
  display: flex;
}

.Header {
  z-index: 100;
  color: var(--header-foreground-color);
  position: sticky;
  top: 0;
}

.Header .Background {
  z-index: 0;
  background-color: var(--header-background-color);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.Header .HeaderContainer {
  justify-content: space-between;
  align-items: center;
  gap: var(--size-gap);
  padding: var(--size-gap-quarter) var(--size-gap);
  text-transform: uppercase;
  flex-direction: row;
  grid-template-columns: 1fr 1fr auto;
  display: grid;
}

@media (width >= 1024px) {
  .Header .HeaderContainer {
    padding: var(--size-gap-quarter);
  }
}

.Header .HeaderContainer .LinksContainer {
  justify-content: flex-end;
  align-items: center;
  gap: var(--size-gap);
  padding: var(--size-gap-half);
  font-size: 12px;
  font-family: var(--font-highlight);
  text-transform: uppercase;
  flex-direction: row;
  padding-right: 0;
  display: flex;
}

.Header .HeaderContainer .LinksContainer .DesktopLinks {
  display: none;
}

@media (width >= 1024px) {
  .Header .HeaderContainer .LinksContainer .DesktopLinks {
    display: inherit;
    gap: inherit;
  }
}

.Header .HeaderContainer .MobileMenuItems .MenuTeams {
  align-items: center;
  gap: var(--size-gap-quarter);
  padding: var(--size-gap);
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.Header .HeaderContainer .MobileMenuItems .MenuTeams .Team {
  border: 1px solid var(--color-darkgray);
  background-color: var(--color-offblack);
  border-radius: 16px;
}

.Header .HeaderContainer .MobileMenuItems .MenuTeams .Team img {
  width: 100%;
  height: 64px;
}

.Header .HeaderContainer .MobileMenuItems .MenuHeader {
  margin: 0 var(--size-gap);
  margin-top: var(--size-gap);
  color: var(--color-gray);
  font-size: var(--font-small);
  font-family: var(--font-highlight);
  text-transform: uppercase;
  padding: 0;
}

.Header .HeaderContainer .MobileMenuItems a {
  align-items: center;
  gap: var(--size-gap-half);
  padding: 0 var(--size-gap);
  color: var(--color-white);
  font-size: var(--font-h3);
  flex-direction: row;
  display: flex;
}

.Header .HeaderContainer .MobileMenuItems a:hover {
  text-decoration: none;
}

.Footer {
  background-color: var(--color-black);
  color: var(--color-white);
  flex-direction: column;
  display: flex;
}

.Footer a {
  color: var(--color-white);
  text-decoration: none;
}

.Footer nav {
  flex-flow: wrap;
  justify-content: center;
  gap: 56px;
  padding: 40px 16px;
  display: flex;
}

@media (width >= 1024px) {
  .Footer nav {
    justify-content: space-between;
    gap: 16px;
    padding: 96px 16px;
  }
}

.Footer nav .LogoContainer {
  flex-flow: wrap;
  justify-content: center;
  display: flex;
}

.Footer nav .LogoContainer .Logo {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.Footer nav .LinkContainer {
  flex-flow: wrap;
  justify-content: space-evenly;
  gap: 32px 58px;
  width: 100%;
  padding: 24px;
  display: flex;
}

@media (width >= 1024px) {
  .Footer nav .LinkContainer {
    width: unset;
    gap: 88px;
    padding: 0;
  }
}

.Footer nav .LinkContainer .Column {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  display: flex;
}

@media (width >= 1024px) {
  .Footer nav .LinkContainer .Column {
    align-items: flex-start;
  }
}

.Footer nav .LinkContainer .Column .Heading {
  color: #979797;
  font-family: VTF League;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 133%;
}

.Footer nav .LinkContainer .Column .Links {
  color: #fff;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  font-size: 14.4px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: flex;
}

@media (width >= 1024px) {
  .Footer nav .LinkContainer .Column .Links {
    align-items: flex-start;
  }
}

.Footer nav .LinkContainer .Column.Follow {
  gap: 24px;
}

.Footer nav .LinkContainer .Column.Follow .Links {
  gap: 12px;
}

.Footer nav .LinkContainer .Column .Social {
  flex-direction: row;
  gap: 8px;
  display: flex;
}

.Footer .Teams {
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  display: flex;
}

.Footer .Teams .Logo {
  text-align: center;
  width: 100%;
  padding: 0 8px;
}

.Footer .Teams .Logo img {
  width: 100%;
  max-width: 40px;
}

.Footer .LegalFooter {
  background-color: #000;
  flex-direction: column;
  padding: 24px 0;
  display: flex;
}

.Footer .LegalFooter .LegalContainer {
  color: #979797;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  display: flex;
}

@media (width >= 1024px) {
  .Footer .LegalFooter .LegalContainer {
    text-align: left;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
  }
}

.Footer .LegalFooter .LegalContainer a {
  color: #979797;
  text-decoration: none;
}

.Footer .LegalFooter .LegalContainer .Copyright {
  flex-direction: column;
  gap: 8px;
  max-width: 438px;
  display: flex;
}

.Footer .LegalFooter .LegalContainer .Links {
  flex-direction: row;
  gap: 8px;
  display: flex;
}

.Footer .LegalFooter .LegalContainer .Pokemon {
  margin-top: 8px;
}

.Layout {
  grid-template-rows: auto 1fr auto;
  display: grid;
  grid-column: full !important;
}

.Layout .LayoutMain {
  background-color: var(--color-lightgray);
}

.NotFound {
  margin-bottom: var(--size-gap-2x);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.NotFound > h1 {
  margin: var(--size-gap);
  font-size: 5em;
}



.Button {
  background-image: linear-gradient(70deg, var(--color-brand-primary), var(--color-brand-primary));
  background-color: var(--color-brand-primary);
  padding: var(--size-gap-half);
  color: var(--color-white);
  text-align: center;
  border-radius: 4px;
  font-weight: 600;
  display: block;
}

.Button:hover {
  text-decoration: none;
}

.Button:hover:after {
  opacity: 1;
}

.Button:after {
  opacity: 0;
  mix-blend-mode: overlay;
  content: "";
  background-image: linear-gradient(120deg, #0000, #00000080);
  border-radius: 4px;
  transition: all .2s;
  position: absolute;
  inset: 0;
}

.Button > div {
  z-index: 1;
}

.Button.Secondary {
  border: 1px solid var(--color-brand-primary);
  background-image: unset;
  background-color: var(--color-white);
  color: var(--color-brand-primary);
}

.Button.Inverted {
  border: 1px solid var(--color-brand-primary);
  background-image: unset;
  color: var(--color-white);
  background-color: #0000;
}

.Banner {
  color: var(--color-white);
  grid-template-columns: 1fr;
  margin: 0;
  display: grid;
}

@media (width >= 1024px) {
  .Banner {
    margin: var(--size-gap) 0;
    grid-template-columns: minmax(0, 600px) 1fr;
  }
}

.Banner .ImageContainer, .Banner video, .Banner picture, .Banner img {
  width: 100%;
  display: block;
}

@media (width >= 1024px) {
  .Banner .ImageContainer, .Banner video, .Banner picture, .Banner img {
    border-radius: 16px 0 0 16px;
  }
}

.Banner .BannerContent {
  background-color: var(--color-black);
  padding: var(--size-gap-2x);
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (width >= 1024px) {
  .Banner .BannerContent {
    text-align: left;
    border-radius: 0 16px 16px 0;
    align-items: flex-start;
    padding: 0 48px;
  }
}

.Banner .ButtonsContainer {
  align-items: stretch;
  gap: var(--size-gap);
  flex-direction: column;
  display: flex;
}

@media (width >= 1024px) {
  .Banner .ButtonsContainer {
    flex-direction: row;
    align-items: flex-end;
  }
}

.Banner .ButtonsContainer .PrimeButton, .Banner .ButtonsContainer .YoutubeButton {
  justify-content: center;
  align-items: center;
  gap: var(--size-gap-half);
  padding: var(--size-gap-half) var(--size-gap);
  color: var(--color-white);
  text-align: center;
  border-radius: 4px;
  display: flex;
}

.Banner .ButtonsContainer .PrimeButton:hover, .Banner .ButtonsContainer .YoutubeButton:hover {
  text-decoration: none;
}

.Banner .ButtonsContainer .PrimeButton svg, .Banner .ButtonsContainer .YoutubeButton svg {
  width: 20px;
  height: 20px;
}

.Banner .ButtonsContainer .PrimeButton {
  border: 1px solid var(--color-amazon-blue);
}

.Banner .ButtonsContainer .YoutubeButton {
  border: 1px solid var(--color-youtube-red);
}

.Banner .ButtonsContainer a {
  margin-top: var(--size-gap-half);
  min-width: 160px;
  max-width: 240px;
}

.Banner h1 {
  margin: 0;
  line-height: 1.3;
}

.Banner p {
  margin: var(--size-gap-half) 0 var(--size-gap);
  max-width: 540px;
  line-height: 1.5;
}

.PictureVideo .PictureContainer, .PictureVideo .VideoContainer {
  display: grid;
}

.PictureVideo .PictureContainer *, .PictureVideo .VideoContainer * {
  grid-area: 1 / 1;
  width: 100%;
}

.PictureVideo .PictureContainer .PicturePlaceholder, .PictureVideo .PictureContainer .VideoPlaceholder, .PictureVideo .VideoContainer .PicturePlaceholder, .PictureVideo .VideoContainer .VideoPlaceholder {
  justify-self: center;
  align-items: center;
  width: 99%;
}

.Table {
  border-radius: unset;
  max-width: var(--app-width);
  overscroll-behavior-x: contain;
  width: 100%;
  padding: 0;
  overflow-x: auto;
}

@media (width >= 1024px) {
  .Table {
    border-radius: var(--size-gap);
    max-width: 100%;
  }
}

.Table table {
  border-spacing: 0;
  table-layout: fixed;
  border-width: 0;
  width: 100%;
}

.Table thead tr th {
  border-bottom: 1px solid var(--color-gray);
  background-color: var(--color-white);
  padding: var(--size-gap);
  color: var(--color-black);
  font-weight: 400;
  font-size: var(--font-small);
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}

.Table thead tr th:first-child {
  z-index: 1;
  border-right: 1px solid var(--color-gray);
  padding: var(--size-gap-half);
  position: sticky;
  left: 0;
}

.Table thead tr th.current {
  background-color: var(--color-brand-secondary);
  color: var(--color-white);
}

.Table thead tr th:hover {
  cursor: pointer;
}

.Table thead tr th.Title {
  padding-left: var(--size-gap);
  text-align: left;
}

.Table tbody a {
  color: var(--color-black);
  text-decoration: none;
}

.Table tbody th {
  z-index: 1;
  border-right: 1px solid var(--color-gray);
  border-bottom: 1px solid var(--color-lightgray);
  background-color: var(--color-white);
  padding: var(--size-gap);
  font-size: var(--font-small);
  font-family: var(--font-main);
  text-align: left;
  position: sticky;
  left: 0;
}

.Table tbody td {
  border-bottom: 1px solid var(--color-lightgray);
  background-color: var(--color-white);
  font-size: var(--font-small);
  text-align: center;
}

.Table tbody td.current {
  background-color: #f4f3f3;
  font-weight: 600;
}

.BoxScore .Table thead tr th {
  width: 60px;
}

.BoxScore .Table tbody .Average th, .BoxScore .Table tbody .Average td {
  border-top: 1px solid var(--color-gray);
}

.BoxScore .Table .Title {
  width: 210px;
  height: 40px;
  padding: 8px;
  transition: width .5s cubic-bezier(.34, 1.56, .64, 1);
}

@media (width >= 1024px) {
  .BoxScore .Table .Title {
    width: 220px;
  }
}

.BoxScore .Table .Title--Collapsed {
  width: 90px;
}

.BoxScore .Table .Title--Collapsed--Long {
  width: 120px;
}

.BoxScore .Table .Title .TitleContentContainer {
  justify-content: space-between;
  display: flex;
}

.BoxScore .Table .Title .CollapseExpandContainer {
  display: flex;
}

.BoxScore .Table .Title .CollapseExpandContainer .CollapseExpandIcon {
  height: 18px;
  transition: transform .5s cubic-bezier(.34, 1.56, .64, 1);
  transform: scaleX(1);
}

.BoxScore .Table .Title .CollapseExpandContainer .CollapseExpandIcon--Collapsed {
  transform: scaleX(-1);
}

.BoxScore .Table .Statistic {
  min-width: 40px;
}

.BoxScore .Table .LeftHeader {
  padding: 8px;
}

.BoxScore .Table .LeftHeader .Profile {
  align-items: center;
  gap: var(--size-gap-half);
  color: var(--color-black);
  flex-direction: row;
  text-decoration: none;
  display: flex;
}

.BoxScore .Table .LeftHeader .Profile .RankImageContainer {
  justify-content: center;
  align-items: center;
  padding: 4px 0;
  display: flex;
}

.BoxScore .Table .LeftHeader .Profile .RankImageContainer .StatRank {
  color: #000;
  text-align: center;
  width: 28px;
  font-size: 16px;
}

.BoxScore .Table .LeftHeader .Profile .RankImageContainer .StatRank p {
  margin: 0;
}

.BoxScore .Table .LeftHeader .Profile .RankImageContainer .ImageNumberContainer {
  padding-left: 4px;
  padding-right: 12px;
  display: flex;
  position: relative;
}

.BoxScore .Table .LeftHeader .Profile .RankImageContainer .ImageNumberContainer .JerseyNumber {
  z-index: 1;
  background-color: var(--color-black);
  font-size: 12px;
  font-family: var(--font-highlight);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}

.BoxScore .Table .LeftHeader .Profile .RankImageContainer .ImageNumberContainer .TeamLogo, .BoxScore .Table .LeftHeader .Profile .RankImageContainer .ImageNumberContainer .PlayerImage {
  border-radius: 50%;
  width: 48px;
  height: 48px;
}

.BoxScore .Table .LeftHeader .Profile .NameContainer {
  opacity: 1;
  color: #000;
  text-overflow: ellipsis;
  line-height: 16px;
  transition: opacity .5s cubic-bezier(.34, 1.56, .64, 1);
  display: flex;
  overflow: hidden;
}

.BoxScore .Table .LeftHeader .Profile .NameContainer--FadeOut {
  opacity: 0;
}

.BoxScore .Table .LeftHeader .Profile .NameContainer--FadeIn {
  opacity: 1;
}

.BoxScore .Table .LeftHeader .Profile .NameContainer .Name {
  text-overflow: ellipsis;
  overflow: hidden;
}

.BoxScore .Table .LeftHeader .Profile .TeamLogo {
  color: var(--color-white);
  font-weight: 600;
  font-size: var(--font-h3);
  font-family: var(--font-highlight);
  background-repeat: no-repeat;
  background-size: contain;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
}

.BoxScore .Table .Average th, .BoxScore .Table .Average td {
  border-top: 1px solid var(--color-gray);
}

.GameCard {
  scroll-snap-align: center;
  color: var(--color-black);
  display: grid;
}

.GameCard a {
  text-decoration: none;
}

.GameCard .GameCardLink {
  grid-area: 1 / 1;
  display: grid;
}

.GameCard .GameCardContainer {
  background-color: var(--color-white);
  pointer-events: none;
  color: var(--color-black);
  border-radius: 16px;
  grid-area: 1 / 1;
  grid-template-rows: auto 1fr;
  display: grid;
}

.GameCard .GameCardContainer .DateContainer {
  border-bottom: 1px solid var(--color-lightgray);
  padding: var(--size-gap-quarter);
  color: var(--color-offblack);
  font-size: var(--font-small);
  text-align: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.GameCard .GameCardContainer .DateContainer .Date {
  padding: var(--size-gap-quarter) 0;
  font-size: var(--font-small);
  flex-direction: row;
  align-items: center;
  display: flex;
}

.GameCard .GameCardContainer .DateContainer .Date.Playoffs {
  padding: var(--size-gap-quarter) var(--size-gap);
  color: var(--color-white);
  background: linear-gradient(#ecd074 0%, #d9aa4e 50%, #ecd074ab 100%);
  border-radius: 100px;
}

.GameCard .GameCardContainer .DateContainer .Date.Live {
  padding: var(--size-gap-quarter) var(--size-gap) var(--size-gap-quarter) var(--size-gap-half);
}

.GameCard .GameCardContainer .DateContainer .Date .BroadcastTime {
  color: var(--color-gray);
}

.GameCard .GameCardContainer .DateContainer .Date .Pill {
  padding: var(--size-gap-quarter) var(--size-gap);
  border-radius: 100px;
}

.GameCard .GameCardContainer .DateContainer .LiveStreamIndicator {
  margin: 0 var(--size-gap-half);
  background: #ff2f2f;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: 2s infinite pulse-red;
  transform: scale(1);
  box-shadow: 0 0 #000;
}

@keyframes pulse-red {
  0% {
    transform: scale(.95);
    box-shadow: 0 0 #ff2f2fb3;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 6px #ff2f2f00;
  }

  100% {
    transform: scale(.95);
    box-shadow: 0 0 #ff2f2f00;
  }
}

.GameCard .GameCardContainer .TeamsContainer {
  justify-content: center;
  align-items: center;
  gap: var(--size-gap-half);
  padding: var(--size-gap-half) var(--size-gap) var(--size-gap);
  flex-direction: row;
  min-width: 304px;
  display: flex;
}

.GameCard .GameCardContainer .TeamsContainer .TeamContainer {
  text-align: center;
  width: 100%;
  max-width: 65px;
}

.GameCard .GameCardContainer .TeamsContainer .TeamContainer.FullTeamName {
  max-width: 240px;
}

.GameCard .GameCardContainer .TeamsContainer .TeamContainer.FullTeamName .TeamCode {
  text-transform: none;
}

.GameCard .GameCardContainer .TeamsContainer .TeamContainer.FullTeamName .TeamCode a {
  color: #000;
}

.GameCard .GameCardContainer .TeamsContainer .TeamContainer .TeamLogo {
  margin: 0 auto;
  margin-bottom: var(--size-gap-half);
  background-size: contain;
  background-color: var(--color-gray);
  pointer-events: all;
  color: var(--color-white);
  font-weight: 600;
  font-size: var(--font-h3);
  font-family: var(--font-highlight);
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
}

.GameCard .GameCardContainer .TeamsContainer .TeamContainer .TeamCode {
  pointer-events: all;
  color: unset;
  font-weight: 600;
  line-height: 1.1;
  font-family: var(--font-highlight);
  text-transform: uppercase;
}

.GameCard .GameCardContainer .TeamsContainer .TeamContainer .TeamCode:last-child {
  grid-column: 5;
}

.GameCard .GameCardContainer .TeamsContainer .TeamContainer .TeamCode.Loser {
  color: var(--color-gray);
}

.GameCard .GameCardContainer .TeamsContainer .TeamContainer .SeriesScore {
  margin-top: var(--size-gap-quarter);
  text-align: center;
  font-size: 12px;
}

.GameCard .GameCardContainer .TeamsContainer .ScoreContainer {
  padding-bottom: var(--size-gap);
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  width: 150px;
  display: grid;
}

.GameCard .GameCardContainer .TeamsContainer .ScoreContainer.Pregame {
  width: 150px;
}

.GameCard .GameCardContainer .TeamsContainer .ScoreContainer .Score {
  font-size: var(--font-h1);
  font-family: var(--font-highlight);
  text-align: center;
}

.GameCard .GameCardContainer .TeamsContainer .ScoreContainer .Score.Loser {
  color: var(--color-gray);
}

.GameCard .GameCardContainer .TeamsContainer .ScoreContainer .StatusContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 70px;
  display: flex;
}

.GameCard .GameCardContainer .TeamsContainer .ScoreContainer .StatusContainer .Status {
  font-weight: 600;
  font-size: var(--font-tiny);
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  max-width: 100px;
  overflow: hidden;
}

.GameCard .GameCardContainer .TeamsContainer .ScoreContainer .StatusContainer .Time {
  color: var(--color-gray);
  font-weight: 600;
  font-size: var(--font-small);
  white-space: nowrap;
}

.GameCard .GameCardContainer .TeamsContainer .ScoreContainer .StatusContainer .IfNeeded {
  padding: var(--size-gap-quarter) 0;
  color: var(--color-gray);
  font-size: 12px;
}

.GameCard .CtaContainer {
  pointer-events: none;
  grid-area: 1 / 1;
  place-content: flex-end center;
  display: grid;
}

.GameCard .CtaContainer .Tickets {
  padding: var(--size-gap);
  pointer-events: all;
  color: var(--color-brand-primary);
  font-weight: 600;
  font-size: var(--font-small);
  flex-direction: row;
  line-height: 1;
  display: flex;
}

.GameCard .CtaContainer .Tickets svg {
  margin-right: var(--size-gap-half);
}

.GameCard .CtaContainer .Tickets.Prime {
  color: var(--color-amazon-blue);
}

.GameCard .CtaContainer .Tickets.Prime img {
  margin-right: var(--size-gap-half);
  width: 2em;
}

.GameCard .CtaContainer .Tickets.Prime--disabled {
  color: gray;
}

.GameCard .CtaContainer .Tickets.Youtube {
  color: var(--color-youtube-red);
}

.GameCard .CtaContainer .Tickets.Youtube img {
  margin-right: var(--size-gap-half);
  width: 2em;
}

.GameCard .CtaContainer .Tickets.Youtube--disabled, .GameCard .CtaContainer .Tickets.Youtube--disabled img {
  color: gray;
}

.GameCard .CtaContainer .Tickets.SoldOut {
  pointer-events: none;
  color: var(--color-gray);
  font-weight: normal;
}

.IndexStandings {
  gap: var(--size-gap);
  background-color: var(--color-white);
  padding: var(--size-gap);
  border-radius: 16px;
  flex-direction: column;
  width: 100%;
  display: flex;
}

@media (width >= 1024px) {
  .IndexStandings {
    background-color: var(--color-white);
    padding: var(--size-gap) var(--size-gap-2x);
    border-radius: 16px;
    margin: 0;
  }
}

.IndexStandings .Team {
  flex-direction: row;
  align-items: center;
  gap: 12px;
  display: flex;
}

.IndexStandings .Team:hover {
  text-decoration: none;
}

.IndexStandings .Team .Rank {
  font-weight: 600;
  font-size: var(--font-h2);
  font-family: var(--font-highlight);
  text-align: center;
  width: .8em;
}

.IndexStandings .Team img, .IndexStandings .Team .LogoPlaceholder {
  aspect-ratio: 1;
  border-radius: 50%;
  width: 48px;
  display: block;
}

.IndexStandings .Team .NameAndOrg {
  flex: 1;
}

.IndexStandings .Team .Name {
  font-family: var(--font-highlight);
}

.IndexStandings .Team .Instagram a {
  color: var(--color-gray);
  text-decoration: none;
}

.IndexStandings .Team .ViewLink {
  flex-direction: row;
  gap: 8px;
  display: flex;
}

.IndexStandings .Team .Record {
  align-items: center;
  gap: var(--size-gap-quarter);
  font-weight: 600;
  font-size: var(--font-h1);
  font-family: var(--font-highlight);
  text-align: center;
  grid-template-columns: 1.3em .5em 1.1em;
  display: grid;
}

.PageHeader {
  background-color: var(--color-brand-primary);
  padding: var(--size-gap);
  color: var(--color-white);
}

@media (width >= 1024px) {
  .PageHeader {
    padding: var(--size-gap) 0;
  }
}

.PageHeader .HeaderContainer {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.PageHeader .HeaderContainer svg {
  padding-left: 4px;
}

.PageHeader .Link {
  font-size: 14px;
}

.PageHeader a {
  color: var(--color-white);
}

.PageHeader a:hover {
  text-decoration: none;
}

.PlayoffsBracket {
  gap: var(--size-gap);
  background-color: var(--color-white);
  padding: var(--size-gap);
  border-radius: 16px;
  flex-direction: column;
  display: flex;
}

@media (width >= 1024px) {
  .PlayoffsBracket {
    padding: var(--size-gap) var(--size-gap-2x);
    margin: 0;
  }
}

.PlayoffsBracket .BracketHeader {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.PlayoffsBracket .BracketHeader a {
  font-size: 14px;
  text-decoration: none;
}

.PlayoffsBracket .BracketCards {
  flex-direction: column;
  display: flex;
}

.PlayoffsBracket .BracketCards .BracketLines {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.PlayoffsBracket .BracketCards .BracketLines.Up {
  flex-direction: column-reverse;
}

.PlayoffsBracket .BracketCards .BracketLines.Up .Branch {
  border: 2px solid #ececec;
  border-bottom: 0;
}

.PlayoffsBracket .BracketCards .BracketLines .Branch {
  border: 2px solid #ececec;
  border-top: 0;
  width: 190px;
  height: 20px;
}

.PlayoffsBracket .BracketCards .BracketLines .Branch.Single {
  display: none;
}

.PlayoffsBracket .BracketCards .BracketLines .Stem {
  border-right: 2px solid #ececec;
  height: 20px;
}

.PlayoffsBracket .BracketCards .BracketCard {
  background: #f9f9f9;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  display: flex;
}

.PlayoffsBracket .BracketCards .BracketCard.Finals {
  background: linear-gradient(#ecd074 0%, #d9aa4e 50%, #ecd074ab 100%);
  border: 2px solid #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 1px 3px #6653290d, 0 6px 6px #6653290d, 0 12px 7px #66532908, 0 22px 9px #66532903, 0 35px 10px #66532900;
}

.PlayoffsBracket .BracketCards .BracketCard.Finals.Loser {
  filter: saturate(0%);
}

.PlayoffsBracket .BracketCards .BracketCard.Finals.Loser .TeamStanding .TeamName, .PlayoffsBracket .BracketCards .BracketCard.Finals.Loser .TeamStanding .Position {
  color: #a7894a;
}

.PlayoffsBracket .BracketCards .BracketCard.Finals .Team .TeamStanding .Position {
  color: #000;
}

.PlayoffsBracket .BracketCards .BracketCard.Finals .Series .Stage, .PlayoffsBracket .BracketCards .BracketCard.Finals .Series .BestOf {
  display: none;
}

.PlayoffsBracket .BracketCards .BracketCard.Current {
  border: 2px solid #ecd074;
}

.PlayoffsBracket .BracketCards .BracketCard.Current .Series .Stage {
  color: #000;
}

.PlayoffsBracket .BracketCards .BracketCard .Team {
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 54px;
  display: flex;
}

.PlayoffsBracket .BracketCards .BracketCard .Team.Loser {
  filter: saturate(0%);
}

.PlayoffsBracket .BracketCards .BracketCard .Team.Loser .TeamStanding .TeamName {
  color: #979797;
}

.PlayoffsBracket .BracketCards .BracketCard .Team .LogoBackground {
  aspect-ratio: 1;
  background-color: #000;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 32px;
  display: grid;
}

.PlayoffsBracket .BracketCards .BracketCard .Team .LogoBackground img {
  width: 16px;
}

.PlayoffsBracket .BracketCards .BracketCard .Team img {
  width: 32px;
}

.PlayoffsBracket .BracketCards .BracketCard .Team .TeamStanding {
  flex-direction: row;
  align-items: center;
  gap: 4px;
  line-height: 1;
  display: flex;
}

.PlayoffsBracket .BracketCards .BracketCard .Team .TeamStanding .Position {
  color: #979797;
  font-size: 11.2px;
  font-weight: 500;
  top: 1px;
}

.PlayoffsBracket .BracketCards .BracketCard .Team .TeamStanding .TeamName {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.PlayoffsBracket .BracketCards .BracketCard .Series {
  flex-direction: column;
  align-items: center;
  width: 135px;
  line-height: 1;
  display: flex;
}

.PlayoffsBracket .BracketCards .BracketCard .Series .Stage {
  color: #979797;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}

.PlayoffsBracket .BracketCards .BracketCard .Series .BestOf {
  color: #979797;
  text-align: center;
  padding: 6px 0 14px;
  font-size: 12px;
  font-weight: 700;
}

.PlayoffsBracket .BracketCards .BracketCard .Series .SeriesScore {
  color: #000;
  font-size: 12px;
  font-weight: 400;
}

.PlayoffsBracket .Eliminations {
  color: #979797;
  text-align: center;
  font-size: 11.2px;
  font-style: italic;
  font-weight: 400;
}

.GameList {
  gap: var(--size-gap);
  padding: var(--size-gap) var(--size-gap) var(--size-gap-2x);
  flex-direction: column;
  display: flex;
}

@media (width >= 1024px) {
  .GameList {
    padding: var(--size-gap) 0 var(--size-gap-2x);
  }
}

.GameList .DateHeaderContainer {
  gap: var(--size-gap);
  flex-direction: column;
  display: flex;
}

.GameList .DateHeaderContainer .DateHeader {
  background: var(--color-brand-secondary);
  padding: var(--size-gap-half) var(--size-gap);
  color: var(--color-white);
  font-weight: 600;
  font-size: var(--font-tiny);
  line-height: 1.6;
  font-family: var(--font-highlight);
  letter-spacing: 2px;
  text-transform: uppercase;
  border-radius: 16px;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.GameList .DateHeaderContainer .NoGames a {
  cursor: pointer;
}

.GameList .DateHeaderContainer .Games {
  gap: var(--size-gap);
  flex-direction: column;
  display: flex;
}

.SeasonLeaders .PlayersContainer {
  gap: var(--size-gap);
  margin-top: var(--size-gap);
  flex-direction: column;
  display: flex;
}

.SeasonLeaders .NoLeaders {
  text-align: center;
}

.SeasonLeaders .Player {
  align-items: center;
  gap: var(--size-gap);
  grid-template-columns: auto minmax(0, 1fr) auto;
  display: grid;
}

.SeasonLeaders .Player:hover {
  text-decoration: none;
}

.SeasonLeaders .Player .ImageContainer {
  aspect-ratio: 1;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  display: flex;
}

.SeasonLeaders .Player .ImageContainer img {
  aspect-ratio: 1;
  border-radius: 50%;
  width: 100%;
}

.SeasonLeaders .Player .ImageContainer img.Placeholder {
  width: 50%;
}

.SeasonLeaders .Player .NameTeam .Name {
  font-family: var(--font-highlight);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.SeasonLeaders .Player .NameTeam .Team {
  color: var(--color-gray);
}

.SeasonLeaders .Player .Statistic {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.SeasonLeaders .Player .Statistic .Value {
  font-size: var(--font-h3);
  font-family: var(--font-highlight);
}

.SeasonLeaders .Player .Statistic label {
  background-color: var(--color-brand-secondary);
  padding: var(--size-gap-quarter) var(--size-gap);
  color: var(--color-white);
  font-weight: 600;
  font-size: var(--font-tiny);
  border-radius: 1000px;
}

.Tabs {
  gap: var(--size-gap);
  margin-bottom: calc(-1 * var(--size-gap));
  padding: 0 var(--size-gap) var(--size-gap);
  max-width: var(--app-width);
  font-size: var(--font-small);
  display: flex;
  overflow-x: auto;
}

.Tabs > a {
  padding: var(--size-gap) var(--size-gap-half) var(--size-gap-half);
  color: var(--color-black);
  white-space: nowrap;
  border-bottom: 4px solid #0000;
  display: block;
}

.Tabs > a:hover {
  text-decoration: none;
}

.Tabs > a.current {
  font-weight: bold;
}

.TeamHeader {
  background-color: var(--color-black);
  padding: var(--size-gap);
  color: #fff;
}

.TeamHeader .HeaderContainer a {
  align-items: center;
  gap: var(--size-gap);
  background-color: #0000;
  flex-direction: row;
  display: flex;
}

.TeamHeader .HeaderContainer a:hover {
  text-decoration: none;
}

.TeamHeader .HeaderContainer a img {
  aspect-ratio: 1;
  border-radius: 50%;
  width: 60px;
}

.TeamPlayer:hover {
  text-decoration: none;
}

.TeamPlayer .Triangle {
  z-index: 1;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 0;
}

.TeamPlayer .ImageContainer {
  margin: var(--size-gap-quarter);
  width: calc(100% - 2 * var(--size-gap-quarter));
}

.TeamPlayer .ImageContainer.Placeholder {
  padding: 40px;
}

.TeamPlayer img {
  aspect-ratio: 1;
  width: 100%;
  display: block;
}

.TeamPlayer .TextContainer {
  margin: var(--size-gap-half);
}

.TeamPlayer .NameNumber {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.TeamPlayer .NameNumber .Number {
  color: var(--color-white);
  font-size: var(--font-small);
  font-family: var(--font-highlight);
}

.TeamPlayer .FirstName {
  color: var(--color-white);
  font-style: italic;
}

.TeamPlayer .LastName {
  font-family: var(--font-highlight);
  text-transform: uppercase;
}

.TeamPlayer .PositionContainer {
  margin-top: var(--size-gap-half);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.TeamPlayer .Position {
  color: var(--color-white);
  font-size: var(--font-tiny);
  text-transform: uppercase;
}

:root {
  --color-brand-primary: #fc4506;
  --color-brand-secondary: #46928f;
  --color-brand-link: #46928f;
  --color-brand-primary-dark: #d61b00;
  --color-brand-primary-modal: #141923fa;
  --color-brand-primary-overlay: #141923b3;
  --color-offblack: #333;
  --color-black: #1e1e1e;
  --color-white: #fff;
  --color-gray: #979797;
  --color-darkgray: #1c1e21;
  --color-lightgray: #efefef;
  --color-offwhite: #fafafa;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --color-amazon-blue: #1a98ff;
  --color-youtube-red: red;
  --font-brand: alternate-gothic-no-3-d, sans-serif;
  --font-main: "roboto flex", helvetica neue, helvetica, arial, sans-serif;
  --font-highlight: vtf league, helvetica neue, helvetica, arial, sans-serif;
  --font-h1: 1.8rem;
  --font-h2: 1.4rem;
  --font-h3: 1.25rem;
  --font-small: .9rem;
  --font-tiny: .7rem;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-large-mobile-width: 640px;
  --size-tablet-width: 768px;
  --size-desktop-width: 990px;
  --size-content-width: 990px;
}

.YouTubeVideos {
  padding-bottom: var(--size-gap);
  padding: 16px var(--size-gap);
}

@media (width >= 1024px) {
  .YouTubeVideos {
    padding: 0;
  }
}

.YouTubeVideos .Videos {
  background-blend-mode: normal, normal, normal, hue;
  background: linear-gradient(90deg, #1e1e1ecc 0%, #0000 57.96%), linear-gradient(#0000 45.92%, #1e1e1ecc 100%), linear-gradient(0deg, #1e1e1ecc 0% 100%), linear-gradient(0deg, #1e1e1e 0% 100%);
  border-radius: 16px;
  grid-template-rows: 1fr auto auto;
  align-items: flex-end;
  gap: 16px;
  max-width: 990px;
  height: 447px;
  display: grid;
}

.YouTubeVideos .Videos video {
  z-index: 0;
  object-fit: cover;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  position: absolute;
}

.YouTubeVideos .Videos .VideoOverlay {
  background: linear-gradient(#32323266 0%, #000 90%);
  border-radius: 16px;
  width: 100%;
  height: 100%;
  position: absolute;
}

.YouTubeVideos .Videos .VideosTitleContainer {
  padding: 0 var(--size-gap);
  color: var(--color-white);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.YouTubeVideos .Videos .VideosTitleContainer h2 {
  font-size: 27px;
}

.YouTubeVideos .Videos .VideosTitleContainer .MoreLink a {
  color: var(--color-white);
  font-size: 14px;
}

.YouTubeVideos .Videos .VideosTitleContainer .MoreLink a svg {
  padding-left: var(--size-gap-quarter);
}

.YouTubeVideos .Videos .VideosListContainer {
  display: grid;
}

.YouTubeVideos .Videos .VideosList {
  padding: 0 var(--size-gap);
  overscroll-behavior-x: contain;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  display: grid;
  overflow-x: auto;
}

.YouTubeVideos .Videos .VideosList > div {
  scroll-snap-align: center;
}

@media (width >= 1024px) {
  .YouTubeVideos .Videos .VideosList {
    grid-template-columns: repeat(4, 1fr);
  }
}

.YouTubeVideos .Videos .VideosList .SmallVideo .ThumbnailContainer {
  border: 1px solid var(--color-white);
  background-color: var(--color-black);
  border-radius: 8px;
  place-items: center;
  min-width: 200px;
  padding: 8px;
  display: grid;
}

.YouTubeVideos .Videos .VideosList .SmallVideo .ThumbnailContainer .Thumbnail {
  border-radius: 8px;
  grid-area: 1 / 1;
  width: 100%;
  display: block;
}

.YouTubeVideos .Videos .VideosList .SmallVideo .ThumbnailContainer .Play {
  grid-area: 1 / 1;
  width: 40px;
}

.YouTubeShorts {
  background-color: var(--color-black);
  margin: 0;
  padding: 16px;
}

.YouTubeShorts.light {
  background-color: var(--color-lightgray);
}

.YouTubeShorts.light h1 {
  color: var(--color-black);
}

@media (width >= 1024px) {
  .YouTubeShorts {
    padding: 32px 0;
  }
}

.YouTubeShorts .ContentContainer > div {
  flex-direction: column;
  gap: 24px;
  display: flex;
}

.YouTubeShorts h1 {
  color: var(--color-white);
}

.YouTubeShorts .Heading {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.YouTubeShorts .Heading h2 {
  color: var(--color-white);
  font: var(--font-headline-m);
}

.YouTubeShorts .Heading .More {
  color: var(--color-brand-primary);
  font: var(--font-body-s-r);
  font-weight: 600;
  display: block;
}

.YouTubeShorts .Heading .More svg {
  color: var(--color-brand-primary);
  margin-left: 4px;
}

.YouTubeShorts .Heading .More.SoldOut {
  color: var(--color-gray);
  text-decoration: line-through;
}

.YouTubeShorts .VideosListContainer {
  display: grid;
}

.YouTubeShorts .VideosList {
  padding-right: var(--size-gap);
  width: calc(100% + var(--size-gap));
  overscroll-behavior-x: contain;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  flex-direction: row;
  align-items: stretch;
  gap: 8px;
  margin: 0;
  display: flex;
  overflow-x: auto;
}

.YouTubeShorts .VideosList > div {
  scroll-snap-align: start;
}

.YouTubeShorts .VideosList .Video {
  gap: var(--size-gap-quarter);
  color: var(--color-white);
  background-color: #000;
  border-radius: 18px;
  flex-direction: column;
  flex-shrink: 1;
  width: 189px;
  height: 100%;
  padding: 8px;
  display: flex;
  overflow: hidden;
}

.YouTubeShorts .VideosList .Video:hover .ThumbnailContainer:after {
  background-color: #0000;
}

.YouTubeShorts .VideosList .Video.Short {
  min-width: 190px;
}

.YouTubeShorts .VideosList .Video .ThumbnailContainer:after {
  z-index: 1;
  content: "";
  background-color: #0000001a;
  width: 175px;
  height: 310px;
  transition: background-color .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.YouTubeShorts .VideosList .Video .Thumbnail {
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
  display: block;
}

.YouTubeShorts .VideosList .Video .Thumbnail.Short {
  width: 175px;
  height: 310px;
}

.YouTubeShorts .VideosList .Video:hover {
  text-decoration: none;
}

.YouTubeShorts .VideosList .Video .TextContainer {
  color: var(--color-brand-primary);
  padding: 8px 16px;
}

.YouTubeShorts .VideosList .Video .TextContainer .Title {
  color: var(--color-white);
  font: var(--font-title-s);
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.YouTubeShorts .VideosList .Video .TextContainer .Description {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  color: var(--color-white);
  font-size: var(--font-small);
  -webkit-box-orient: vertical;
  line-height: 1.5;
  display: -webkit-box;
  overflow: hidden;
}

.Picture {
  width: 100%;
}

.Picture img {
  width: 100%;
  display: block;
}

.Picture .PictureFull {
  z-index: 1;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/*# sourceMappingURL=app.css.map */
