@use '~/src/overtime-lib/src/www/lib/mixins';
.Header {
	position: sticky;
	top: 0;
	z-index: 100;

	color: var(--header-foreground-color);
	.Background {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		background-color: var(--header-background-color);
		width: 100%;
		height: 100%;
	}
	.HeaderContainer {
		display: grid;
		grid-template-columns: 1fr 1fr auto;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		gap: var(--size-gap);
		padding: var(--size-gap-quarter) var(--size-gap);
		text-transform: uppercase;
		@include mixins.desktop {
			padding: var(--size-gap-quarter);
		}

		.LinksContainer {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;
			gap: var(--size-gap);
			padding: var(--size-gap-half);
			padding-right: 0;
			font-size: 12px;
			font-family: var(--font-highlight);
			text-transform: uppercase;
			.DesktopLinks {
				display: none;
				@include mixins.desktop {
					display: inherit;
					gap: inherit;
				}
			}
		}

		.MobileMenuItems {
			.MenuTeams {
				display: grid;
				grid-template-columns: 1fr 1fr;
				align-items: center;
				gap: var(--size-gap-quarter);
				padding: var(--size-gap);
				.Team {
					border: 1px solid var(--color-darkgray);
					border-radius: 16px;
					background-color: var(--color-offblack);
					img {
						width: 100%;
						height: 64px;
					}
				}
			}

			.MenuHeader {
				margin: 0 var(--size-gap);
				margin-top: var(--size-gap);
				padding: 0;
				color: var(--color-gray);
				font-size: var(--font-small);
				font-family: var(--font-highlight);
				text-transform: uppercase;
			}

			a {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: var(--size-gap-half);
				padding: 0 var(--size-gap);
				color: var(--color-white);
				font-size: var(--font-h3);
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}
