.cookie-consent {
	display: flex;
	position: fixed;
	bottom: 0;
	left: 0;
	flex-direction: row;
	justify-content: center;
	transform: translateY(100%);
	z-index: 100;
	backdrop-filter: blur(10px);
	transition: transform 0.3s ease-in;
	transition-delay: 0.1s;
	background-color: rgba(255, 255, 255, 0.85);
	padding: 16px;
	width: 100%;
	height: 100px;
	color: black;

	&--dark {
		background-color: rgba(0, 0, 0, 0.85);
		color: white;

		.cookie-consent__container button {
			border: 1px solid white;
			color: white;
		}
	}

	&--visible {
		transform: translateY(0);
		transition-delay: 1s;
	}

	&__container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 16px;
		width: 100%;
		font-weight: 700;
		font-size: 12px;

		svg {
			flex-shrink: 0;
			width: 25px;
			height: 30px;
		}

		button {
			cursor: pointer;
			border: 1px solid #000000;
			border-radius: 4px;
			background-color: unset;
			padding: 8px;
			width: 100%;
			max-width: 80px;
			color: #000000;
			font-weight: 700;
			font-size: 12px;
		}
	}
}
