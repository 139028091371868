@use '~/src/overtime-lib/src/www/lib/mixins';

.IndexStandings {
	display: flex;
	flex-direction: column;
	gap: var(--size-gap);
	border-radius: 16px;
	background-color: var(--color-white);
	padding: var(--size-gap);
	width: 100%;

	@include mixins.desktop {
		margin: 0;
		border-radius: 16px;
		background-color: var(--color-white);
		padding: var(--size-gap) var(--size-gap-2x);
	}

	.Team {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 12px;

		&:hover {
			text-decoration: none;
		}

		.Rank {
			width: 0.8em;
			font-weight: 600;
			font-size: var(--font-h2);
			font-family: var(--font-highlight);
			text-align: center;
		}

		img,
		.LogoPlaceholder {
			display: block;
			border-radius: 50%;
			aspect-ratio: 1;
			width: 48px;
		}

		.NameAndOrg {
			flex: 1;
		}

		.Name {
			font-family: var(--font-highlight);
		}

		.Instagram {
			a {
				color: var(--color-gray);
				text-decoration: none;
			}
		}

		.ViewLink {
			display: flex;
			flex-direction: row;
			gap: 8px;
		}
		.Record {
			display: grid;
			grid-template-columns: 1.3em 0.5em 1.1em;
			align-items: center;
			gap: var(--size-gap-quarter);
			font-weight: 600;
			font-size: var(--font-h1);
			font-family: var(--font-highlight);
			text-align: center;
		}
	}
}
