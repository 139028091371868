@use '~/src/overtime-lib/src/www/lib/mixins';

.SeasonLeaders {
	.PlayersContainer {
		display: flex;
		flex-direction: column;
		gap: var(--size-gap);
		margin-top: var(--size-gap);
		@include mixins.desktop {
		}
	}
	.NoLeaders {
		text-align: center;
	}
	.Player {
		display: grid;
		grid-template-columns: auto minmax(0, 1fr) auto;
		align-items: center;
		gap: var(--size-gap);
		&:hover {
			text-decoration: none;
		}

		.ImageContainer {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			aspect-ratio: 1;
			width: 60px;
			img {
				border-radius: 50%;
				aspect-ratio: 1;
				width: 100%;
				&.Placeholder {
					width: 50%;
				}
			}
		}
		.NameTeam {
			.Name {
				overflow: hidden;
				font-family: var(--font-highlight);
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.Team {
				color: var(--color-gray);
			}
		}
		.Statistic {
			display: flex;
			flex-direction: column;
			align-items: center;
			.Value {
				font-size: var(--font-h3);
				font-family: var(--font-highlight);
			}
			label {
				border-radius: 1000px;
				background-color: var(--color-brand-secondary);
				padding: var(--size-gap-quarter) var(--size-gap);
				color: var(--color-white);
				font-weight: 600;
				font-size: var(--font-tiny);
			}
		}
	}
}
