.TeamHeader {
	background-color: var(--color-black);
	padding: var(--size-gap);
	color: white;
	.HeaderContainer {
		a {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: var(--size-gap);
			background-color: transparent;
			&:hover {
				text-decoration: none;
			}
			img {
				border-radius: 50%;
				aspect-ratio: 1;
				width: 60px;
			}
		}
	}
}
