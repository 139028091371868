//This file is automatically generated by the build process
//Edit styles.json then run `npm run build` to update
$color-brand-primary: #fc4506;
$color-brand-secondary: #46928f;
$color-brand-link: #46928f;
$color-brand-primary-dark: #d61b00;
$color-brand-primary-modal: rgba(20,25,35,0.98);
$color-brand-primary-overlay: rgba(20,25,35,0.7);
$color-offblack: #333;
$color-black: #1e1e1e;
$color-white: #ffffff;
$color-gray: #979797;
$color-darkgray: #1c1e21;
$color-lightgray: #efefef;
$color-offwhite: #fafafa;
$color-shadow: rgba(0,0,0,0.25);
$color-inner-shadow: rgba(0,0,0,0.05);
$color-amazon-blue: #1a98ff;
$color-youtube-red: #ff0000;
$font-brand: alternate-gothic-no-3-d, sans-serif;
$font-main: 'roboto flex', helvetica neue, helvetica, arial, sans-serif;
$font-highlight: vtf league, helvetica neue, helvetica, arial, sans-serif;
$font-h1: 1.8rem;
$font-h2: 1.4rem;
$font-h3: 1.25rem;
$font-small: 0.9rem;
$font-tiny: 0.7rem;
$size-gap: var(--gap);
$size-gap-quarter: calc(var(--gap) / 4);
$size-gap-half: calc(var(--gap) / 2);
$size-gap-2x: calc(var(--gap) * 2);
$size-gap-3x: calc(var(--gap) * 3);
$size-gap-4x: calc(var(--gap) * 4);
$size-gap-6x: calc(var(--gap) * 6);
$size-row-gap: var(--row-gap);
$size-large-mobile-width: 640px;
$size-tablet-width: 768px;
$size-desktop-width: 990px;
$size-content-width: 990px;
:root {
--color-brand-primary: #fc4506;
--color-brand-secondary: #46928f;
--color-brand-link: #46928f;
--color-brand-primary-dark: #d61b00;
--color-brand-primary-modal: rgba(20,25,35,0.98);
--color-brand-primary-overlay: rgba(20,25,35,0.7);
--color-offblack: #333;
--color-black: #1e1e1e;
--color-white: #ffffff;
--color-gray: #979797;
--color-darkgray: #1c1e21;
--color-lightgray: #efefef;
--color-offwhite: #fafafa;
--color-shadow: rgba(0,0,0,0.25);
--color-inner-shadow: rgba(0,0,0,0.05);
--color-amazon-blue: #1a98ff;
--color-youtube-red: #ff0000;
--font-brand: alternate-gothic-no-3-d, sans-serif;
--font-main: 'roboto flex', helvetica neue, helvetica, arial, sans-serif;
--font-highlight: vtf league, helvetica neue, helvetica, arial, sans-serif;
--font-h1: 1.8rem;
--font-h2: 1.4rem;
--font-h3: 1.25rem;
--font-small: 0.9rem;
--font-tiny: 0.7rem;
--size-gap: var(--gap);
--size-gap-quarter: calc(var(--gap) / 4);
--size-gap-half: calc(var(--gap) / 2);
--size-gap-2x: calc(var(--gap) * 2);
--size-gap-3x: calc(var(--gap) * 3);
--size-gap-4x: calc(var(--gap) * 4);
--size-gap-6x: calc(var(--gap) * 6);
--size-row-gap: var(--row-gap);
--size-large-mobile-width: 640px;
--size-tablet-width: 768px;
--size-desktop-width: 990px;
--size-content-width: 990px;
}
