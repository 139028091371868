@use '../lib/mixins.scss';

.MenuIcon {
	padding: 4px;
	@include mixins.desktop {
		display: none;
	}

	&[aria-expanded='true'] {
		padding-right: 5px;
		padding-left: 5px;
	}
}
.MobileMenu {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	// Give hint to browser that this will be seen and needs rendering
	content-visibility: visible;

	@include mixins.desktop {
		display: none;
	}

	.Menu {
		display: block;
		position: absolute;
		top: 100%;
		left: 0;
		backdrop-filter: blur(10px);
		transition: transform 0.3s ease;
		background-color: rgba(0, 0, 0, 0.85);
		padding-bottom: var(--size-gap);
		width: var(--app-width);
		// Need to be the height of the visible browser space minus the height of the header, i.e., 100% of our container
		height: calc(100dvh - 100%);
		overflow-y: auto;
		overscroll-behavior: contain;
		color: var(--theme-foreground-color);
		&[aria-hidden='true'] {
			transform: translateY(-100%);
		}

		nav {
			display: flex;
			flex-direction: column;
			gap: var(--size-gap-half);
			text-transform: none;
		}
	}
}
