.Picture {
	width: 100%;

	img {
		display: block;
		width: 100%;
	}

	.PictureFull {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
	}
}
