.TeamPlayer {
	&:hover {
		text-decoration: none;
	}
	.Triangle {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 24px;
		height: 24px;
	}
	.ImageContainer {
		margin: var(--size-gap-quarter);
		width: calc(100% - 2 * var(--size-gap-quarter));
		&.Placeholder {
			padding: 40px;
		}
	}
	img {
		display: block;
		aspect-ratio: 1;
		width: 100%;
	}

	.TextContainer {
		margin: var(--size-gap-half);
	}
	.NameNumber {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.Number {
			color: var(--color-white);
			font-size: var(--font-small);
			font-family: var(--font-highlight);
		}
	}
	.FirstName {
		color: var(--color-white);
		font-style: italic;
	}
	.LastName {
		font-family: var(--font-highlight);
		text-transform: uppercase;
	}

	.PositionContainer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-top: var(--size-gap-half);
	}

	.Position {
		color: var(--color-white);
		font-size: var(--font-tiny);
		text-transform: uppercase;
	}
}
