@use '../lib/mixins';

.NotFound {
	@include mixins.center-column;

	margin-bottom: var(--size-gap-2x);

	> h1 {
		margin: var(--size-gap);
		font-size: 5em;
	}
}
