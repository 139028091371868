@use '~/src/overtime-lib/src/www/lib/mixins';

.GameList {
	display: flex;
	flex-direction: column;
	gap: var(--size-gap);
	padding: var(--size-gap) var(--size-gap) var(--size-gap-2x);

	@include mixins.desktop {
		padding: var(--size-gap) 0 var(--size-gap-2x);
	}

	.DateHeaderContainer {
		display: flex;
		flex-direction: column;
		gap: var(--size-gap);

		.DateHeader {
			display: flex;
			justify-content: center;
			align-items: flex-start;
			border-radius: 16px;
			background: var(--color-brand-secondary);
			padding: var(--size-gap-half) var(--size-gap);
			color: var(--color-white);
			font-weight: 600;
			font-size: var(--font-tiny);
			line-height: 1.6;
			font-family: var(--font-highlight);
			letter-spacing: 2px;
			text-transform: uppercase;
		}

		.NoGames {
			a {
				cursor: pointer;
			}
		}

		.Games {
			display: flex;
			flex-direction: column;
			gap: var(--size-gap);
		}
	}
}
