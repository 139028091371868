.PictureVideo {
	.PictureContainer,
	.VideoContainer {
		display: grid;

		* {
			grid-area: 1/1;
			width: 100%;
		}

		// We do this because the video element isn't always a clean 16/9 at
		// certain screen sizes, so the image will sometimes peak a pixel
		// or 2 bigger than the video
		.PicturePlaceholder,
		.VideoPlaceholder {
			align-items: center;
			justify-self: center;
			width: 99%;
		}
	}
}
