@use '~/src/overtime-lib/src/www/lib/mixins';

.LoadingBoundarySuspense {
	@include mixins.center-column;
	width: 100%;
}

.LoadingBoundaryError {
	@include mixins.center-column;
	flex-direction: row;
	gap: var(--size-gap-half);
	font-family: var(--font-highlight);
}
