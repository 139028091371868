.Tabs {
	display: flex;
	gap: var(--size-gap);
	margin-bottom: calc(-1 * var(--size-gap));
	padding: 0 var(--size-gap) var(--size-gap);
	max-width: var(--app-width);
	overflow-x: auto;
	font-size: var(--font-small);
	> a {
		display: block;
		border-bottom: 4px solid transparent;
		padding: var(--size-gap) var(--size-gap-half) var(--size-gap-half);
		color: var(--color-black);
		white-space: nowrap;

		&:hover {
			text-decoration: none;
		}

		&.current {
			font-weight: bold;
		}
	}
}
