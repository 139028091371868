@use '~/src/overtime-lib/src/www/lib/mixins';

.PlayoffsBracket {
	display: flex;
	flex-direction: column;
	gap: var(--size-gap);
	border-radius: 16px;
	background-color: var(--color-white);
	padding: var(--size-gap);

	@include mixins.desktop {
		margin: 0;
		padding: var(--size-gap) var(--size-gap-2x);
	}

	.BracketHeader {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		a {
			font-size: 14px;
			text-decoration: none;
		}
	}

	.BracketCards {
		display: flex;
		flex-direction: column;

		.BracketLines {
			display: flex;
			flex-direction: column;
			align-items: center;

			&.Up {
				flex-direction: column-reverse;

				.Branch {
					border: 2px solid #ececec;
					border-bottom: 0;
				}
			}

			.Branch {
				border: 2px solid #ececec;
				border-top: 0;
				width: 190px;
				height: 20px;

				&.Single {
					display: none;
				}
			}
			.Stem {
				border-right: 2px solid #ececec;
				height: 20px;
			}
		}

		.BracketCard {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			border-radius: 8px;
			background: #f9f9f9;
			padding: 12px;

			&.Finals {
				box-shadow:
					0px 1px 3px 0px rgba(102, 83, 41, 0.05),
					0px 6px 6px 0px rgba(102, 83, 41, 0.05),
					0px 12px 7px 0px rgba(102, 83, 41, 0.03),
					0px 22px 9px 0px rgba(102, 83, 41, 0.01),
					0px 35px 10px 0px rgba(102, 83, 41, 0);
				border: 2px solid #f9f9f9;
				border-radius: 8px;
				background: linear-gradient(180deg, #ecd074 0%, #d9aa4e 50%, rgba(236, 208, 116, 0.67) 100%);

				&.Loser {
					filter: saturate(0%);
					.TeamStanding {
						.TeamName {
							color: #a7894a;
						}
						.Position {
							color: #a7894a;
						}
					}
				}

				.Team {
					.TeamStanding {
						.Position {
							color: #000;
						}
					}
				}

				.Series {
					.Stage {
						display: none;
					}
					.BestOf {
						display: none;
					}
				}
			}

			&.Current {
				border: 2px solid #ecd074;

				.Series {
					.Stage {
						color: #000;
					}
				}
			}

			.Team {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 4px;
				width: 54px;

				&.Loser {
					filter: saturate(0%);
					.TeamStanding {
						.TeamName {
							color: #979797;
						}
					}
				}
				.LogoBackground {
					display: grid;
					justify-content: center;
					align-items: center;
					border-radius: 50%;
					background-color: #000;
					aspect-ratio: 1/1;
					width: 32px;

					img {
						width: 16px;
					}
				}

				img {
					width: 32px;
				}

				.TeamStanding {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 4px;
					line-height: 1;

					.Position {
						top: 1px;
						color: #979797;
						font-weight: 500;
						font-size: 11.2px;
					}

					.TeamName {
						color: #000;
						font-weight: 500;
						font-size: 16px;
					}
				}
			}

			.Series {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 135px;
				line-height: 1;

				.Trophy {
				}

				.Stage {
					color: #979797;
					font-weight: 700;
					font-size: 12px;
					text-align: center;
					text-transform: uppercase;
				}

				.BestOf {
					padding: 6px 0 14px;
					color: #979797;
					font-weight: 700;
					font-size: 12px;
					text-align: center;
				}

				.SeriesScore {
					color: #000;
					font-weight: 400;
					font-size: 12px;
				}
			}
		}
	}

	.Eliminations {
		color: #979797;
		font-style: italic;
		font-weight: 400;
		font-size: 11.2px;
		text-align: center;
	}
}
