@use '~/src/overtime-lib/src/www/lib/mixins';

.GameCard {
	display: grid;
	scroll-snap-align: center;
	color: var(--color-black);

	a {
		text-decoration: none;
	}

	.GameCardLink {
		display: grid;
		grid-area: 1/1;
	}

	.GameCardContainer {
		display: grid;
		grid-template-rows: auto 1fr;
		grid-area: 1/1;
		border-radius: 16px;
		background-color: var(--color-white);
		pointer-events: none;
		color: var(--color-black);

		.DateContainer {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			border-bottom: 1px solid var(--color-lightgray);
			padding: var(--size-gap-quarter);
			width: 100%;
			color: var(--color-offblack);
			font-size: var(--font-small);
			text-align: center;

			.Date {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: var(--size-gap-quarter) 0;
				font-size: var(--font-small);

				&.Playoffs {
					border-radius: 100px;
					background: linear-gradient(180deg, #ecd074 0%, #d9aa4e 50%, rgba(236, 208, 116, 0.67) 100%);
					padding: var(--size-gap-quarter) var(--size-gap);
					color: var(--color-white);
				}

				&.Live {
					padding: var(--size-gap-quarter) var(--size-gap) var(--size-gap-quarter) var(--size-gap-half);
				}

				.BroadcastTime {
					color: var(--color-gray);
				}

				.Pill {
					border-radius: 100px;
					padding: var(--size-gap-quarter) var(--size-gap);
				}
			}

			.LiveStreamIndicator {
				transform: scale(1);
				animation: pulse-red 2s infinite;
				margin: 0 var(--size-gap-half);
				box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
				border-radius: 50%;
				background: rgb(255, 47, 47);
				width: 12px;
				height: 12px;
			}

			@keyframes pulse-red {
				0% {
					transform: scale(0.95);
					box-shadow: 0 0 0 0 rgba(255, 47, 47, 0.7);
				}

				70% {
					transform: scale(1);
					box-shadow: 0 0 0 6px rgba(255, 47, 47, 0);
				}

				100% {
					transform: scale(0.95);
					box-shadow: 0 0 0 0 rgba(255, 47, 47, 0);
				}
			}
		}

		.TeamsContainer {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			gap: var(--size-gap-half);
			padding: var(--size-gap-half) var(--size-gap) var(--size-gap);
			min-width: 304px;

			.TeamContainer {
				width: 100%;
				max-width: 65px;

				// Firefox needs to use text align instead of justify-items on this element
				text-align: center;

				&.FullTeamName {
					max-width: 240px;

					.TeamCode {
						text-transform: none;
						a {
							color: black;
						}
					}
				}

				.TeamLogo {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					margin: 0 auto;
					margin-bottom: var(--size-gap-half);
					border-radius: 50%;
					background-size: contain;
					background-color: var(--color-gray);
					width: 40px;
					height: 40px;
					pointer-events: all;
					color: var(--color-white);
					font-weight: 600;
					font-size: var(--font-h3);
					font-family: var(--font-highlight);
				}

				.TeamCode {
					pointer-events: all;
					color: unset;
					font-weight: 600;
					line-height: 1.1;
					font-family: var(--font-highlight);
					text-transform: uppercase;

					&:last-child {
						grid-column: 5;
					}

					&.Loser {
						color: var(--color-gray);
					}
				}

				.SeriesScore {
					margin-top: var(--size-gap-quarter);
					font-size: 12px;
					text-align: center;
				}
			}

			.ScoreContainer {
				display: grid;
				grid-template-columns: 1fr auto 1fr;
				align-items: center;
				padding-bottom: var(--size-gap);
				width: 150px;

				&.Pregame {
					width: 150px;
				}

				.Score {
					font-size: var(--font-h1);
					font-family: var(--font-highlight);
					text-align: center;
					&.Loser {
						color: var(--color-gray);
					}
				}

				.StatusContainer {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					min-width: 70px;

					.Status {
						max-width: 100px;
						overflow: hidden;
						font-weight: 600;
						font-size: var(--font-tiny);
						text-overflow: ellipsis;
						text-transform: uppercase;
						white-space: nowrap;
					}

					.Time {
						color: var(--color-gray);
						font-weight: 600;
						font-size: var(--font-small);
						white-space: nowrap;
					}

					.IfNeeded {
						padding: var(--size-gap-quarter) 0;
						color: var(--color-gray);
						font-size: 12;
					}
				}
			}
		}
	}

	.CtaContainer {
		display: grid;
		grid-area: 1/1;
		align-content: flex-end;
		justify-content: center;
		pointer-events: none;

		.Tickets {
			display: flex;
			flex-direction: row;
			padding: var(--size-gap);
			pointer-events: all;
			color: var(--color-brand-primary);
			font-weight: 600;
			font-size: var(--font-small);
			line-height: 1;

			svg {
				margin-right: var(--size-gap-half);
			}

			&.Prime {
				color: var(--color-amazon-blue);

				img {
					margin-right: var(--size-gap-half);
					width: 2em;
				}

				&--disabled {
					color: gray;
				}
			}

			&.Youtube {
				color: var(--color-youtube-red);

				img {
					margin-right: var(--size-gap-half);
					width: 2em;
				}

				&--disabled {
					color: gray;

					img {
						color: gray;
					}
				}
			}

			&.SoldOut {
				pointer-events: none;
				color: var(--color-gray);
				font-weight: normal;
			}
		}
	}
}
